<template>
    <v-container dense>
        <v-row dense>
            <v-col>
                <BT-Select
                    navigation="products"
                    label="Product"
                    v-model="item.productID"
                    item-text="productName"
                    item-value="id" />
            </v-col>
            <v-col>
                <BT-Select
                    :items="rules"
                    v-model="item.rule"
                    item-text="text"
                    item-value="value"
                    label="Rule" />
            </v-col>
            <v-col>
                <div v-if="!isNoRule">
                    <v-text-field
                        v-model.number="item.quantity"
                        :label="showValueLabel ? 'Value ($$$)' : 'Quantity'"
                        :title="showIncrementsLabel ? 'units must be divisible by this number' : null" 
                        :prefix="showValueLabel ? '$' : null"
                        type="number" />
                </div>
            </v-col>
            <v-col cols="1">
                <v-btn @click="remove" icon><v-icon>mdi-delete</v-icon></v-btn>
            </v-col>
        </v-row>
    </v-container>                                                     
</template>

<script>
    export default {
        name: 'Ordering-Rule',
        data: function () {
            return {
                rules: [
                    { text: 'No Rule', value: 'NoRule' }, 
                    { text: 'Value Less Than', value: 'ValueLessThan' }, 
                    { text: 'Value More Than', value: 'ValueMoreThan' }, 
                    { text: 'Value Equal To', value: 'ValueEqualTo' }, 
                    { text: 'Quantity In Increments', value: 'QuantityInIncrements' }, 
                    { text: 'Quantity Less Than', value: 'QuantityLessThan' }, 
                    { text: 'Quantity More Than', value: 'QuantityMoreThan' },
                    { text: 'Quantity Equal To', value: 'QuantityEqualTo' },
                    { text: 'Global Value Less Than', value: 'GlobalValueLessThen' }, 
                    { text: 'Global Value More Than', value: 'GlobalValueMoreThan' }, 
                    { text: 'Global Value Equal To', value: 'GlobalValueEqualTo' }],
            }
        },
        props: {
            item: null,
        },        
        computed: {
            showValueLabel() {
                return this.item.rule === this.rules[1].value || 
                    this.item.rule === this.rules[2].value || 
                    this.item.rule === this.rules[3].value || 
                    this.item.rule === this.rules[8].value || 
                    this.item.rule === this.rules[9].value || 
                    this.item.rule === this.rules[10].value;
            },
            isNoRule() {
                return this.item.rule === this.rules[0].value;
            },
            showIncrementsLabel() {
                return this.item.rule === this.rules[4].value;
            }
        },
        methods: {
            remove() {
                this.$emit('remove');
            },
            update() {
                this.$emit('change');
            }
        }
    }
</script>

<style scoped>
</style>